<template>
	<v-container fluid class="white-background main-create-page">
		<div class="py-1">
			<v-row>
				<v-col cols="6" md="6" class="my-auto">
					<h1 class="heading-h3 mb-0">
						<template v-if="role_id > 0">Update Role</template
						><template v-else>Create a new Role</template>
					</h1>
				</v-col>
				<v-col cols="6" md="6" class="text-right">
					<v-btn v-on:click="goBack()" :disabled="formLoading || pageLoading" depressed tile>
						Cancel
					</v-btn>
					<v-btn
						:disabled="formLoading || pageLoading"
						:loading="formLoading"
						class="mx-2 custom-bold-button white--text"
						v-on:click="onSubmit"
						tile
						depressed
						color="blue darken-4 white--text"
					>
						Save Role
					</v-btn>
				</v-col>
			</v-row>
		</div>
		<v-form
			ref="roleForm"
			v-model.trim="formValid"
			lazy-validation
			v-on:submit.stop.prevent="onSubmit"
		>
			<perfect-scrollbar
				:options="{ suppressScrollX: true }"
				class="scroll custom-box-top-inner-shadow"
				style="height: calc(100vh - 150px); position: relative"
			>
				<v-container fluid>
					<v-row>
						<v-col md="10" lg="6" class="py-0">
							<v-container fluid class="py-0">
								<TextInput
									hide-details
									:disabled="pageLoading"
									:loading="pageLoading"
									id="name"
									:rules="[
										vrules.required(updateRole.role_name, 'Role Name'),
										vrules.minLength(updateRole.role_name, 'Role Name', 2),
										vrules.maxLength(updateRole.role_name, 'Role Name', 100),
									]"
									:class="{
										required: !updateRole.role_name,
									}"
									placeholder="Role Name"
									v-model="updateRole.role_name"
								></TextInput>
							</v-container>
						</v-col>
					</v-row>
					<v-row>
						<v-col md="10" lg="6" class="py-0">
							<v-container fluid class="py-0 pt-2">
								<TextAreaInput
									hide-details
									:disabled="pageLoading"
									:loading="pageLoading"
									id="description"
									placeholder="Description"
									v-model="updateRole.description"
									:rules="[
										vrules.minLength(updateRole.description, 'Description', 1),
										vrules.maxLength(updateRole.description, 'Description', 1024),
									]"
								></TextAreaInput>
							</v-container>
						</v-col>
					</v-row>
					<v-row>
						<v-col md="10" class="pt-4">
							<v-container fluid class="py-0 pt-2">
								<Permissions
									:permissionarray="permissionsData"
									:updatePermissionsList="formPermissionsList"
									v-on:updatePermissionData="updatePermissionData"
								></Permissions>
							</v-container>
						</v-col>
					</v-row>
				</v-container>
			</perfect-scrollbar>
		</v-form>
	</v-container>
</template>

<script>
import { mapGetters } from "vuex";
import {
	GetPermission,
	CreateRole,
	AssignedRolePermission,
	PostAssignedRolePermission,
	GetRole,
	UpdateRole,
} from "@/core/lib/setting.lib";
import Permissions from "@/view/module/setting/rolePermission/Permission";
import { SET_ERROR, SET_MESSAGE } from "@/core/services/store/common.module";
import { toSafeInteger } from "lodash";
import TextInput from "@/view/components/TextInput";
import TextAreaInput from "@/view/components/TextAreaInput";

export default {
	name: "role-create",
	data() {
		return {
			role_id: 0,
			clone_id: 0,
			updateRole: {
				role_name: null,
				description: null,
			},
			formPermissionsList: [],
			permissionsData: [],
			pageLoading: false,
			formValid: true,
			formLoading: false,
			permissionLoading: true,
			routePreventDialog: false,
		};
	},
	components: {
		Permissions,
		TextInput,
		TextAreaInput,
	},
	methods: {
		onSubmit() {
			const _this = this;
			const formErrors = _this.validateForm(_this.$refs.roleForm);
			if (formErrors.length) {
				_this.$store.commit(SET_ERROR, _this.errors.concat(formErrors));
				return false;
			}

			if (!_this.$refs.roleForm.validate()) {
				return false;
			}

			let formData = {
				text: this.updateRole.role_name,
				description: this.updateRole.description,
			};
			if (this.formPermissionsList.length == 0) {
				this.$store.commit(SET_ERROR, [
					{ model: true, message: "Please Select Atleast One Permission." },
				]);
				return false;
			}
			if (this.role_id > 0) {
				UpdateRole(formData, this.role_id)
					.then((data) => {
						this.$store.commit(SET_MESSAGE, [
							{ model: true, message: "Success ! Role has been created." },
						]);
						this.assignedPermissions(data.id);
					})
					.catch((error) => {
						this.logError(error);
						this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
					})
					.finally(() => {
						this.pageLoading = false;
					});
			} else {
				CreateRole(formData)
					.then((data) => {
						this.$store.commit(SET_MESSAGE, [
							{ model: true, message: "Success ! Role has been created." },
						]);
						this.assignedPermissions(data.id);
					})
					.catch((error) => {
						this.logError(error);
						this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
					})
					.finally(() => {
						this.pageLoading = false;
					});
			}
		},
		assignedPermissions(roleId) {
			console.log("roleId", roleId);
			console.log("route", this.$route);
			if (this.$route.name == "role.update") {
				AssignedRolePermission({ permission: this.formPermissionsList }, roleId)
					.then(() => {
						this.$store.commit(SET_MESSAGE, [
							{ model: true, message: "Success ! Role assigned permissions successfully" },
						]);
					})
					.catch((error) => {
						this.logError(error);
						this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
					})
					.finally(() => {
						this.pageLoading = false;
						this.$router.push({ name: "setting", query: { tab: "role_permission" } });
					});
			} else {
				PostAssignedRolePermission({ permission: this.formPermissionsList }, roleId)
					.then(() => {
						this.$store.commit(SET_MESSAGE, [
							{ model: true, message: "Success ! Role assigned permissions successfully" },
						]);
					})
					.catch((error) => {
						this.logError(error);
						this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
					})
					.finally(() => {
						this.pageLoading = false;
						this.$router.push({ name: "setting", query: { tab: "role_permission" } });
					});
			}
		},
		updatePermissionData(data) {
			this.formPermissionsList = data;
		},
		getPermission() {
			const _this = this;
			_this.pageLoading = true;
			GetPermission(Number(this.$route.params.id))
				.then((data) => {
					_this.permissionsData = data || new Array();
					_this.pageLoading = false;
					_this.permissionLoading = false;
					if (_this.$route.params.id) {
						_this.pageLoading = true;
						_this.role_id = toSafeInteger(_this.$route.params.id);
						_this.loadContent(_this.role_id);
					}

					if (_this.$route.query.clone) {
						_this.pageLoading = true;
						_this.clone_id = toSafeInteger(_this.$route.query.clone);
						_this.loadContent(_this.clone_id);
					}
				})
				.catch((error) => {
					this.logError(error);
					this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		loadContent(role_id) {
			const _this = this;
			GetRole(role_id)
				.then(({ role, description, permissions }) => {
					_this.updateRole = {
						role_name: role,
						description: description,
					};
					if (permissions && permissions.length > 0) {
						let result = permissions.map((row) => {
							return toSafeInteger(row.permission_id);
						});
						_this.formPermissionsList = result;
					}
				})
				.catch((error) => {
					_this.logError(error);
				})
				.finally(() => {
					_this.pageLoading = false;
				});
		},
	},
	mounted() {
		this.getPermission();
	},
	computed: {
		...mapGetters(["errors"]),
	},
};
</script>
